<template>
  <v-card class="pa-3">
    <v-row>
      <v-col>
        <v-simple-table width="50%">
          <tr>
            <td></td>
            <td>
              <v-spacer />
              <v-text-field
                v-model="searchService"
                append-icon="mdi-magnify"
                class="ml-auto mb-2"
                label="Search"
                hide-details
                single-line
                style="max-width: 250px"
              />
            </td>
          </tr>
        </v-simple-table>
        <v-data-table
          :headers="headers"
          :items="tableData"
          loading-text="Loading data...."
          :loading="loading"
          :options="dataTableOptions"
          :search="searchService"
          dense
          class="centerheadings"
        >
          <template v-slot:item="{ item }">
            <tr @click="handleRowClick(item)">
              <td style="min-width: 120px" class="ServiceText">
                <v-icon v-if="item.rowIndex == selectedRow"> mdi-forward </v-icon>
                {{ item.sn }}
              </td>
              <td style="min-width: 220px">
                {{ item.userName }}
              </td>
              <td>
                {{ item.snStatus }}
              </td>
              <td>
                {{ Utils.formatDate(item.snStartDate) }}
              </td>
              <td>
                {{ Utils.formatDate(item.flexiStart) }}
              </td>
              <td>
                {{ item.flexiPlanName }}
              </td>
              <td>
                {{ item.sim }}
              </td>
              <td>
                {{ item.deviceName }}
              </td>
              <td>
                {{ item.imei }}
              </td>
            </tr>
          </template>
        </v-data-table>
        <v-btn
          color="primary"
          dark
          class="mb-2 pa-0"
          :before-generate="beforeGenerateExcel"
          :before-finish="beforeFinishExcel"
          @click="handleDownload"
        >
          Download <v-icon small class="mr-1" right> mdi-download </v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import _ from 'lodash'
import { configuredOptions, filterUsers } from '@/api/ConfiguredOptionReport'
import store from '@/store'
import Utils from '@/api/util'

export default {
  name: 'ServiceDetailsTable',
  computed: {
    Utils () {
      return Utils
    }
  },
  data() {
    return {
      selectedRow: -1,
      loading: false,
      selectedOptions: [],
      searchService: '',
      headers: [
        {
          text: 'Mobile No.',
          align: 'start',
          value: 'sn'
        },
        { text: 'Name', value: 'userName' },
        // { text: "Service Activation Date", value: "snActivationDate" },
        { text: 'Service Status', value: 'snStatus' },
        { text: 'Service Start Date', value: 'snStartDate' },
        { text: 'Flexiplan Start Date', value: 'flexiStart' },
        { text: 'Flexiplan Name', value: 'flexiPlanName' },
        { text: 'Sim Serial', value: 'sim' },
        { text: 'Device Name', value: 'deviceName' },
        { text: 'IMEI', value: 'imei' },
        // {
        //   text: "No Usage Last 6 Months",
        //   align: "center",
        //   value: "noUsageSixMonths",
        // },
      ],
      tableData: [],
      dataTableOptions: {
        itemsPerPage: 50
      },
      excelTitle: 'Service Details Data',
      excelFileName: 'Service Details Export',
      excelFileType: 'xlsx'
    }
  },
  watch: {},
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.filterServiceNumberWithOptions()
    },
    exportToExcel() {
      return this.tableData
    },
    beforeGenerateExcel() {
      store.dispatch('app/setProgress', true)
    },
    beforeFinishExcel() {
      store.dispatch('app/setProgress', false)
    },
    handleDownload() {
      import('@/vendor/Export2Excel').then((excel) => {
        const tHeader = []
        const filterVal = []
        const dateFields = ['snStartDate', 'flexiStart']

        this.headers.forEach((item, index) => {
          tHeader.push(item.text)
          filterVal.push(item.value)
        })

        const list = this.tableData
        const data = list.map((v) =>
          filterVal.map((j) =>
            dateFields.includes(j) ? Utils.formatDate(v[j]) : v[j]
          )
        )
        excel.export_json_to_excel({
          header: tHeader,
          sheetTitle: 'Services',
          data,
          filename: this.excelFileName,
          bookType: this.excelFileType
        })
      })
    },
    filterServiceNumberWithOptions() {
      const searchOptions = []
      this.loading = true
      this.selectedOptions.forEach((optionIndex) => {
        searchOptions.push(this.tags[optionIndex].name)
      })
      return filterUsers(searchOptions).then((result) => {
        console.log('filterWithOptions user account found ' + result.length)
        // need to add row index....
        let rowIndex = 0
        result.forEach((serviceNumber) => {
          serviceNumber.rowIndex = rowIndex++
        })
        this.tableData = result
        this.selectedRow = -1
        this.loading = false
      })
    },
    handleRowClick(value) {
      console.log('handleRowClick rowindex is ' + value.rowIndex)
      this.selectedRow = value.rowIndex
      store.dispatch('configuredOption/updateSnFilter', { sn: value.sn })
    }
  }
}
</script>

<style scoped></style>
