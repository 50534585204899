<template>
  <v-container
  style="min-width:1366px;max-width:1920px;"
  >
    <service-details-table
      
    />
  </v-container>
</template>

<script>
  import ServiceDetailsTable from '@/views/blitz/components/ServiceDetailsTable'

  export default {
    name: 'ServiceDetailsComponent',
    components: {
      ServiceDetailsTable,
    },
    data () {
      return {
        
      }
    },
  }
</script>

<style scoped>

</style>
