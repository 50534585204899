<template>
  <v-container
    class="pt-0"
    style="min-width:1366px;max-width:1920px;"
  >
    <template v-if="showSnDetail">
      <v-breadcrumbs
        :items="crumbs"
        style="padding-top: 0"
      >
        <template v-slot:divider>
          <v-icon>mdi-chevron-right</v-icon>
        </template>
        <template v-slot:item="props">
          <v-chip
            :color="!props.item.disabled?'':'#FCDBE6'"
            :text-color="!props.item.disabled?'':'#EB3573'"
            @click="handleBreadCrumbClick(props.item.code)"
          >
            {{ props.item.text }}
          </v-chip>
        </template>
      </v-breadcrumbs>
      <service-number-page :sn-data="snFilter.sn" />
    </template>
    <component
      :is="currentComponent"
      v-show="showHomePage"
    />
  </v-container>
</template>

<script>
  import ServiceDetailsComponent from '@/views/blitz/pages/ServiceDetailsComponent'
  import ServiceNumberPage from '@/views/blitz/pages/ServiceNumberPage'

  import { mapGetters } from 'vuex'

  export default {
    name: 'ServiceDetails',
    components: {
      ServiceDetailsComponent,
      ServiceNumberPage,
    },
    computed: {
      ...mapGetters('configuredOption', {
        snFilter: 'snFilter', //, ...
      }),
      showHomePage () {
        console.log('showHomePage() returning ' + !this.showSnDetail)
        return !this.showSnDetail
      },
    },
    data () {
      return {
        showSnDetail: false,
        currentComponent: 'ServiceDetailsComponent',
        crumbs: [
          {
            text: 'Service Details',
            disabled: false,
            code: 'configuredOptions',
            href: 'breadcrumbs_dashboard',
          },
        ],
        table_crumbs: [
          {
            text: 'Service Details',
            disabled: false,
            code: 'configuredOptions',
            href: 'breadcrumbs_dashboard',
          },
        ],
        sn_crumbs: [
          {
            text: 'Service Details',
            disabled: false,
            code: 'configuredOptions',
            href: 'breadcrumbs_dashboard',
          },
          {
            text: 'Service Detail',
            code: 'serviceNumber',
            disabled: true,
            href: 'breadcrumbs_dashboard',
          },
        ],

      }
    },
    watch: {
      snFilter: 'handleServiceNumberClick'
    },
    mounted () {
      this.$gtag.pageview({
        page_path: '/configuredOptions',
      })
    },
    methods: {
      handleServiceNumberClick () {
        console.log('snFilter change to ' + this.snFilter)
        this.crumbs = this.sn_crumbs
        this.showSnDetail = true
      },
      handleBreadCrumbClick (code) {
        console.log('handleBreadCrumbClick value is ' + code)
        this.showSnDetail = false
        this.crumbs = this.table_crumbs
      },
    },
  }
</script>

<style scoped>

</style>
